// Generated by Framer (58c7c96)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Hkyeqvfzp"];

const variantClassNames = {Hkyeqvfzp: "framer-v-1d75ksi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "Hkyeqvfzp", image: iMuXZmTYr, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Hkyeqvfzp", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-AjT8u", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 548, intrinsicWidth: 631, pixelHeight: 1096, pixelWidth: 1262, ...toResponsiveImage_194x2gw(iMuXZmTYr)}} className={cx("framer-1d75ksi", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Hkyeqvfzp"} ref={ref} style={{...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-AjT8u [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AjT8u * { box-sizing: border-box; }", ".framer-AjT8u .framer-hu06jw { display: block; }", ".framer-AjT8u .framer-1d75ksi { height: 334px; overflow: visible; position: relative; width: 384px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 333.5
 * @framerIntrinsicWidth 384
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"iMuXZmTYr":"image"}
 */
const FramerakNOh3E7I: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerakNOh3E7I;

FramerakNOh3E7I.displayName = "element";

FramerakNOh3E7I.defaultProps = {height: 333.5, width: 384};

addPropertyControls(FramerakNOh3E7I, {iMuXZmTYr: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerakNOh3E7I, [])